import React from "react"

import BreakoutGroupSocketConsumer from "../../../../stores/socket/breakout_group_socket/breakoutGroupSocketConsumer"
import TrainingSocketConsumer from "../../../../stores/socket/training_socket/trainingSocketConsumer"
import DataSocketConsumer from "../../../../stores/socket/data_socket/dataSocketConsumer"
import ClassroomDashboard from "../../../../components/ClassroomDashboard"
import QuestionStore from "../../../../stores/question_store/QuestionStore"
import InsightStore from "../../../../stores/insight_store/InsightStore"

const ClassroomDashboardWrapper = (location) => {
  let trainingId = location.training_id

  return (
    <BreakoutGroupSocketConsumer link={"/ws/breakout-groups/"}>
      <TrainingSocketConsumer link={`/ws/training-activity/${trainingId}/`}>
        <DataSocketConsumer link={`/ws/fetch-data/${location.template_id}/`}>
          <QuestionStore>
            <InsightStore>
              <ClassroomDashboard trainingId={trainingId} templateId={location.template_id} />
            </InsightStore>
          </QuestionStore>
        </DataSocketConsumer>
      </TrainingSocketConsumer>
    </BreakoutGroupSocketConsumer>
  )
}

export default ClassroomDashboardWrapper
