import React, { useEffect, useState, useRef, useContext } from "react"
import { Link, navigate } from "gatsby"

import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import Modal from "react-bootstrap/Modal"
import Dropdown from "react-bootstrap/Dropdown"
import Image from "../custom/Image"

// import axios from "axios"
import { DragDropContext } from "react-beautiful-dnd"
import StylesA from "./style.module.scss"

import SharedArtifact from "../shared-artifact"
// import Encryption from "../../services/encryption"

import ClassroomSidebar from "../live_class/classroom_sidebar/classroomSidebar"
import ParticipantDetail from "../template-resources/participant-detail"
import formatDate from "../../services/format_date"
import ExternalLinksToDownload from "../external-links-to-download"
import NotificatioBellAndModal from "../live_class/header/notificationBellAndModal"
import InsightModal from "../insights/InsightModal"

import CancelWhite from "../../images/svgs/close.svg"
// import BellWhite from "../../images/svgs/bell-white-icon.svg"
// import PollsBg from "../../images/svgs/classroom/polls-bg.svg"
import BreakoutBg from "../../images/svgs/classroom/breakout-bg.svg"
import LessonSessionBg from "../../images/svgs/classroom/lesson-session-bg.svg"
import ResourceBg from "../../images/svgs/classroom/resource-bg.svg"
// import GuidlinesBg from "../../images/svgs/classroom/guidlines-bg.png"
import LinksToDownload from "../../images/svgs/classroom/links-to-download-bg.png"
import ListIcon from "../../images/svgs/classroom/list-gray-icon.svg"
import BreakoutGroup from "../../images/svgs/classroom/breakout-group-gray-iocn.svg"
import PollIcon from "../../images/svgs/classroom/poll-gray-icon.svg"
import LessonSessionIcon from "../../images/svgs/classroom/lesson-and-session-plan-card-icon.svg"
import SlideshowIcon from "../../images/svgs/classroom/music-and-slideshow-icon.svg"
import ResourcesIcon from "../../images/svgs/classroom/resources-large-icon.svg"
import GoliveBanner from "../../images/svgs/classroom/classroom-golive-icon.svg"
import ExternalLinksDownload from "../../images/svgs/classroom/tools-to-be-downloaded.svg"
import share from "../../images/share.png"
import InsightIcon from "../../images/svgs/insights/insights.svg"
import SessionBurnDownIcon from "../../images/svgs/insights/session_burndown_insights.svg"

import LockIcon from "../../images/svgs/lock-white-icon.svg"
// import LessonModalImage from "../../images/svgs/classroom/lesson_plan_modal_bg.svg"
// import SessionModalImage from "../../images/svgs/classroom/session_plan_modal_bg.svg"
import BreakoutSessionModalImage from "../../images/svgs/classroom/breakout_session_modal_bg.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink } from "@fortawesome/free-solid-svg-icons"

import { popupBlockerChecker } from "../utilities/poup-permission"

import utcDateTime from "../../services/utcdatetime"
// import { WsConnect } from "../../services/socket"
// import { isBrowser } from "../../services/developer_mode"
import { ClassroomContext } from "../../stores/classroom_store/ClassroomStore"
import { QuestionContext } from "../../stores/question_store/QuestionStore"
import {
  SET_OPENED_OVERLAY_SECTION,
  SET_TEMPLATE_ID,
  SET_TRAINING_ID,
  SET_SHARED_RESOURCE_DRAG_ENDED,
  SET_COMMON_RESOURCE_DRAG_ENDED,
  SET_BREAKOUT_RESOURCE_DRAG_ENDED,
} from "../../stores/classroom_store/ClassroomActions"
import BreakoutGroups from "../template-resources/breakout-groups/breakoutGroups"
import { ClassroomSidebarContext } from "../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"
import { SET_CURRENT_NAV_TAB, SET_NAV_AND_RESOURCE_TYPE, SET_DRAGGED_ITEM } from "../../stores/classroom_store/classroom_sidebar/classroomSidebarActions"
import BreakoutSession from "../template-resources/BreakoutSession"
import { getRequest, putRequest } from "../../services/rest_service"
import MuralCanvas from "../mural-canvas"
import { useAssessmentMural } from "../../stores/assessment_mural_store/AssessmentMuralStore"
import {
  setActiveFilter,
  setMuralAssessment,
  setMuralAssessmentId,
  setMuralChatWindowExpanded,
  setPartipantId,
  setShowMuralCanvas,
  setShowParticipantDetailsModal,
} from "../../stores/assessment_mural_store/AssessmentMuralActions"
import QuestionSingle from "../question-answer/QuestionSingle"
import { InsightContext } from "../../stores/insight_store/InsightStore"
import { SET_INSIGHT_DATA } from "../../stores/insight_store/InsightActions"

import InsightIconStyle from "../insights/LessonBoardInsight/style.module.scss"

const ClassroomDashboard = ({ trainingId, templateId }) => {
  const [classroomStore, dispatchClassroomStore] = useContext(ClassroomContext)
  const [sidebarStore, dispatchSidebarStore] = useContext(ClassroomSidebarContext)
  const [insightStore, insightDispatch] = useContext(InsightContext)
  const [state, assessmentMuralDispatch] = useAssessmentMural()
  const [questionStore, questionDispatch] = useContext(QuestionContext)
  const [activeSection, setActiveSection] = useState("Home")
  const [participantData, setParticipantData] = useState(null)
  const [dragEnded, setDragEnded] = useState(false)
  const [draggedItem, setDraggedItem] = useState("")
  const [currentSelectedResourcesType, setCurrentSelectedResourcesType] = useState("")

  const [allResources, setAllResources] = useState({})
  const [allResourcesChanged, setAllResourcesChanged] = useState(false)
  const [trainingDetail, setTrainingDetail] = useState("")
  const [trainingDetailFetched, setTrainingDetailFetched] = useState(false)
  const [trainingStateChanged, setTrainingStateChanged] = useState(false)
  const [lesson_session_activity_fetched, setLessonSessionActivityFetched] = useState(false)
  const [breakout_activity_fetched, setBreakoutActivityFetched] = useState(false)
  const [resource_activity_fetched, setResourceActivityFetched] = useState(false)
  const [extlinks_activity_fetched, setExtLinksActivityFetched] = useState(false)
  const [lesson_session_plan, setLessonSessionPlan] = useState([])
  const [breakout_plan, setBreakoutPlan] = useState([])
  const [resource_activity, setResourceActivity] = useState([])
  const [extlinks_activity, setExtLinksActivity] = useState([])

  const [breakoutSessionCount, setBreakoutSessionCount] = useState(null)
  const [breakoutRoomCount, setBreakoutRoomCount] = useState(null)
  const [showSessionModal, setShowSessionModal] = useState(false)
  const [showExtLinkModal, setShowExtLinkModal] = useState(false)
  const [participant, setParticipant] = useState(null)
  const [showSharedArtifact, setShowSharedArtifact] = useState(false)
  const leftNavOpen = sidebarStore.currentNavTab !== "" && sidebarStore.currentNavTab !== activeSection

  useEffect(() => {
    if (sidebarStore.currentNavTab != "Resource" && sidebarStore.currentNavTab != "Participants" && sidebarStore.currentNavTab != "") {
      assessmentMuralDispatch(setShowMuralCanvas(false))
      assessmentMuralDispatch(setPartipantId(null))
      assessmentMuralDispatch(setMuralAssessmentId(null))
      assessmentMuralDispatch(setMuralAssessment(null))
      navigate(window.location.pathname)
    }
    if (sidebarStore.currentNavTab === "BreakoutSession") {
      setActiveSection("BreakoutSession")
    } else if (sidebarStore.currentNavTab === "BreakoutGroup") {
      setActiveSection("Home")
    }
  }, [sidebarStore.currentNavTab])
  useEffect(() => {
    fetchTrainingDetail()
    dispatchClassroomStore({ type: SET_TRAINING_ID, payload: trainingId })
    dispatchClassroomStore({ type: SET_TEMPLATE_ID, payload: templateId })
    assessmentMuralDispatch(setShowMuralCanvas(false))
    assessmentMuralDispatch(setMuralChatWindowExpanded(false))
  }, [])
  useEffect(() => {
    if (!lesson_session_activity_fetched) {
      fetchLessonSessionRecentActivity()
    }
  }, [lesson_session_activity_fetched])
  useEffect(() => {
    if (!breakout_activity_fetched) {
      fetchBreakoutRecentActivity()
    }
  }, [breakout_activity_fetched])
  useEffect(() => {
    if (!resource_activity_fetched) {
      fetchResourceRecentActivity()
    }
  }, [resource_activity_fetched])

  useEffect(() => {
    if (!extlinks_activity_fetched) {
      fetchExtLinksRecentActivity()
    }
  }, [extlinks_activity_fetched])

  const fetchTrainingDetail = async (viewLiveClassClicked = false, trainingClassStatus = false) => {
    let res = await getRequest(`/my-training-desc/${trainingId}/`)
    if (res.success) {
      setTrainingDetail(res.data[0])
      setTrainingDetailFetched(true)
      if (trainingClassStatus) {
        changeTrainingStatus(viewLiveClassClicked, trainingClassStatus)
      }
    } else {
      // TODO : handle api fail
    }
  }
  const fetchLessonSessionRecentActivity = async () => {
    let res = await getRequest(`/classroom-recent-activity/${templateId}/${trainingId}/?activity_type=lesson_session_activity`)
    if (res.success) {
      let lesson_plan = res.data.lesson_plan.map((data) => ({ ...data, type: "lesson_plan" }))
      let session_plan = res.data.session_plan.map((data) => ({ ...data, type: "session_plan" }))
      let lesson_session_plan_data = lesson_plan.concat(session_plan)

      if (lesson_session_plan_data.length === lesson_plan.length + session_plan.length) {
        if (lesson_session_plan_data.length > 1) {
          setLessonSessionPlan(
            lesson_session_plan_data.sort((prev_value, next_value) => {
              return utcDateTime.utcDateTime(next_value.created, "time_in_sec") - utcDateTime.utcDateTime(prev_value.created, "time_in_sec")
            })
          )
        } else {
          setLessonSessionPlan(lesson_session_plan_data)
        }
      }
      setLessonSessionActivityFetched(true)
    } else {
      setLessonSessionActivityFetched(false)
      setLessonSessionPlan([])
    }
  }

  const fetchBreakoutRecentActivity = async () => {
    let res = await getRequest(`/classroom-recent-activity/${templateId}/${trainingId}/?activity_type=breakout_activity`)
    if (res.success) {
      setBreakoutSessionCount(res.data.breakout_session_count)
      setBreakoutRoomCount(res.data.breakout_group_count)
      let breakout_session = res.data.breakout_session.map((data) => ({ ...data, type: "breakout_session" }))
      let breakout_group = res.data.breakout_group.map((data) => ({ ...data, type: "breakout_group" }))
      let breakout_plan_data = breakout_session.concat(breakout_group)

      if (breakout_plan_data.length === breakout_session.length + breakout_group.length) {
        if (breakout_plan_data.length > 1) {
          setBreakoutPlan(
            breakout_plan_data.sort((prev_value, next_value) => {
              return utcDateTime.utcDateTime(next_value.created, "time_in_sec") - utcDateTime.utcDateTime(prev_value.created, "time_in_sec")
            })
          )
        } else {
          setBreakoutPlan(breakout_plan_data)
        }
      }
      setBreakoutActivityFetched(true)
    } else {
      setBreakoutActivityFetched(false)
      setBreakoutPlan([])
    }
  }

  const fetchResourceRecentActivity = async () => {
    let res = await getRequest(`/classroom-recent-activity/${templateId}/${trainingId}/?activity_type=resource_activity`)
    if (res.success) {
      let resource_data = res.data.map((data) => ({ ...data, type: "resource_activity" }))
      if (resource_data.length === res.data.length) {
        if (resource_data.length > 1) {
          setResourceActivity(
            resource_data.sort((prev_value, next_value) => {
              return utcDateTime.utcDateTime(next_value.created, "time_in_sec") - utcDateTime.utcDateTime(prev_value.created, "time_in_sec")
            })
          )
        } else {
          setResourceActivity(resource_data)
        }
      }
      setResourceActivityFetched(true)
    } else {
      setResourceActivityFetched(false)
      setResourceActivity([])
    }
  }

  function ReformatExtLinks(data) {
    let newData = [],
      timeAgoPrev = null,
      prevIdx = -1,
      timeAgo,
      j = 0

    for (let i = 0, k = 0; i < data.length; ++i) {
      timeAgo = pollTimeDifference(data[i].created)
      if (timeAgoPrev !== timeAgo) {
        if (prevIdx >= 0) {
          newData[prevIdx].totalLinks = j
          j = 0
        }
        timeAgoPrev = timeAgo
        newData.push({ entity: 1, totalLinks: 0, timeAgo: timeAgoPrev || timeAgo })
        prevIdx = k
        newData.push({ entity: 2, data: data[i] })
        ++j
        k += 2
      } else {
        newData.push({ entity: 2, data: data[i] })
        ++j
        ++k
      }
    }
    if (prevIdx >= 0) {
      newData[prevIdx].totalLinks = j
    }

    setExtLinksActivity(newData)
  }

  const openMuralModalForAssessment = () => {
    assessmentMuralDispatch(setMuralAssessmentId(undefined))
    assessmentMuralDispatch(setShowParticipantDetailsModal(true))
    assessmentMuralDispatch(setActiveFilter("quizzes"))
  }

  const fetchExtLinksRecentActivity = async () => {
    try {
      let res = await getRequest(`/tool-links/?defaultcoursetemplate=${templateId}&ordering=-id`)
      if (res.success) {
        if (res.data["results"]?.length > 0) {
          ReformatExtLinks(res.data.results)
        } else {
          setExtLinksActivity([])
        }
        setExtLinksActivityFetched(true)
      } else {
        setExtLinksActivityFetched(false)
        setExtLinksActivityFetched([])
      }
    } catch (error) {
      console.error(error)
    }
  }

  const changeTrainingStatus = async (viewLiveClassClicked = false, trainingDataUpdated = false) => {
    try {
      if (trainingDataUpdated === false) {
        fetchTrainingDetail(viewLiveClassClicked, true)
        return
      }

      setTrainingStateChanged(true)
      let currDate = new Date().toISOString().slice(0, 10)
      let encrypted_id = trainingId

      if (trainingDetail?.training_daily_status?.date == currDate) {
        if (viewLiveClassClicked) {
          let popup = window.open(`/main/live-class/${templateId}/${encrypted_id}`, `liveclass${trainingId}${templateId}`)
          popupBlockerChecker.check(popup)
          return
        }
        let formData = new FormData()
        let res = await putRequest(`/change-training-state/${trainingId}/?status=1&date=${currDate}`, formData)
        if (res.success) {
          setTrainingStateChanged(false)
          let popup = window.open(`/main/live-class/${templateId}/${encrypted_id}`, `liveclass${trainingId}${templateId}`)
          popupBlockerChecker.check(popup)
          setTimeout(() => {
            window.location.reload()
          }, 100)
        } else {
          // TODO : handle api error
        }
      } else {
        //Yesterday's class not ended, so end it & then begin the class
        let yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        yesterday = yesterday.toISOString().slice(0, 10)

        let class_status = "3"
        let end_dt = new Date(trainingDetail.end_date)
        let current_dt = new Date()
        let currDate = current_dt.toISOString().slice(0, 10)

        if (current_dt.getTime() < end_dt.getTime()) {
          class_status = "2"
        }

        //End the Previous Day Class
        let formData = new FormData()
        let res = await putRequest(`/change-training-state/${trainingId}/?status=${class_status}&date=${yesterday}`, formData)
        if (res.success) {
          let res = await putRequest(`/change-training-state/${trainingId}/?status=1&date=${currDate}`, formData)
          if (res.success) {
            setTrainingStateChanged(false)
            let popup = window.open(`/main/live-class/${templateId}/${encrypted_id}`, `liveclass${trainingId}${templateId}`)
            popupBlockerChecker.check(popup)
            setTimeout(() => {
              window.location.reload()
            }, 100)
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  function onDragEnd(result) {
    if (result) {
      if (classroomStore.openedOverlaySection === "BreakoutGroup") {
        dispatchClassroomStore({ type: SET_COMMON_RESOURCE_DRAG_ENDED, payload: true })
      } else if (classroomStore.openedOverlaySection === "ActiveBreakoutSession") {
        dispatchClassroomStore({ type: SET_SHARED_RESOURCE_DRAG_ENDED, payload: true })
      } else if (classroomStore.openedOverlaySection === "BreakoutSession") {
        dispatchClassroomStore({ type: SET_BREAKOUT_RESOURCE_DRAG_ENDED, payload: true })
      }
      dispatchSidebarStore({ type: SET_DRAGGED_ITEM, payload: result })
    } else {
      return null
    }
  }

  function openNavWithResourceSection(nav_tab, resource_type) {
    dispatchSidebarStore({ type: SET_NAV_AND_RESOURCE_TYPE, payload: { navTab: nav_tab, resourceType: resource_type } })
  }

  function showGoliveSection() {
    let current_date = utcDateTime.utcDateTime("today")
    let start_date = utcDateTime.utcDateTime(trainingDetail.start_date)
    let end_date = utcDateTime.utcDateTime(trainingDetail.end_date)
    let started = trainingDetail.class_status === "1" ? true : false
    if (current_date < end_date) {
      if (current_date < start_date) {
        if (started) {
          return showGotoClassButton()
        } else {
          let time_difference = start_date.getTime() - current_date.getTime()
          let resulted_day = Math.floor(time_difference / (1000 * 60 * 60 * 24))
          if (time_difference / (1000 * 60) > 15) {
            return (
              <div className="pl-3">
                {resulted_day > 0 ? (
                  <p style={{ color: "#E0E2E5" }}>{`Your class is scheduled to go live on ${utcDateTime.utcDateTime(
                    trainingDetail.start_date,
                    "date_with_prefix"
                  )} ${utcDateTime.utcDateTime(trainingDetail.start_date, "short_month_name")}, at ${utcDateTime.utcDateTime(
                    trainingDetail.start_date,
                    "time"
                  )}`}</p>
                ) : (
                  <p style={{ color: "#E0E2E5" }}>{`Your class is scheduled to go live in ${convertTimestampToHourMin(time_difference / 1000)} Hours`}</p>
                )}
                <Button
                  disabled={true}
                  className={`${StylesA.border_on_hover}`}
                  style={{ border: "1px solid #707070", color: "#E0E2E5", position: "relative" }}
                  variant="none"
                >
                  <img
                    src={LockIcon}
                    alt={`lock`}
                    className={`my-auto`}
                    style={{ objectFit: `contain`, width: "13px", position: "absolute", right: "0px", bottom: "-6px" }}
                  />
                  <a style={{ color: "#E0E2E5" }}>Go Live</a>
                </Button>
              </div>
            )
          } else {
            return showGoliveButton()
          }
        }
      } else {
        if (started) {
          return showGotoClassButton()
        } else {
          return showGoliveButton()
        }
      }
    } else if (current_date > end_date && started) {
      return showGotoClassButton()
    } else {
      return <p style={{ color: "#E0E2E5" }}>Class Completed.</p>
    }
  }

  function viewInsights() {
    return (
      <Dropdown className="breakout-groups move-dropdown mt-3 ml-3">
        <Dropdown.Toggle className={`border-none shadow-none px-0 py-0`} id="classroom-insight-icon">
          <div className="d-flex align-items-center bg-303C54 px-3 py-1 br-4px bs-1 pointer">
            <Image src={InsightIcon} className={``} height={"23px"} width={"21px"} alt={`Session Burndown`} />
            <p className="mb-0 fs-12px bold-500 text-white pl-3">View Insights</p>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className=" mt-2">
          <Dropdown.Item
            onClick={(e) => {
              e.preventDefault()
              insightDispatch({ type: SET_INSIGHT_DATA, payload: { data: {}, type: "session_burndown", activeChart: "Session Burndown Chart" } })
            }}
            className="text-white fs-14px px-2 align-items-center d-flex"
          >
            <Image src={SessionBurnDownIcon} className={`${InsightIconStyle.insight_list_icon}`} alt={`Session Burndown`} />
            <span className="ml-1">Session Burndown</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={(e) => {
              e.preventDefault()
              insightDispatch({ type: SET_INSIGHT_DATA, payload: { data: {}, type: "class_burndown", activeChart: "Class Burndown" } })
            }}
            className="text-white fs-14px px-2 align-items-center d-flex"
          >
            <Image src={SessionBurnDownIcon} className={`${InsightIconStyle.insight_list_icon}`} alt={`Class Burndown`} />
            <span className="ml-1">Class Burndown</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const convertTimestampToHourMin = (timestamp) => {
    let hour = Math.floor(timestamp / (60 * 60))
    let minute = Math.floor((timestamp - hour * 60 * 60) / 60)
    hour = hour < 10 ? `0${hour}` : `${hour}`
    minute = minute < 10 ? `0${minute}` : `${minute}`
    return `${hour}:${minute}`
  }

  function showGoliveButton() {
    return (
      <div className="pl-3">
        <p style={{ color: "#E0E2E5" }}>Time's up. Go live anytime!</p>
        <Button
          disabled={trainingStateChanged}
          className={`${StylesA.border_on_hover} d-flex`}
          onClick={() => changeTrainingStatus()}
          style={{ border: "1px solid #707070", color: "#E0E2E5", alignItems: "center" }}
          variant="none"
        >
          {trainingStateChanged ? <Spinner animation="border" variant="secondary" size="sm" className="mr-2" /> : ""}
          Go live
        </Button>
      </div>
    )
  }
  function showGotoClassButton() {
    return (
      <div className="pl-3">
        <p style={{ color: "#E0E2E5" }}>Class started.</p>
        <Button
          className={`${StylesA.border_on_hover}`}
          style={{ border: "1px solid #707070", color: "#E0E2E5" }}
          variant="none"
          onClick={() => changeTrainingStatus(true)}
        >
          View Live Class
        </Button>
      </div>
    )
  }
  const displayRecentActivity = (type) => {
    if (type === "lesson_session_card") {
      if (lesson_session_activity_fetched && lesson_session_plan && lesson_session_plan.length > 0) {
        return (
          <Col xs="12" sm="6" md="6" lg="3" className={`${StylesA.recent_card} px-0 mx-1`}>
            <div className={`${StylesA.card_header}`} style={{ backgroundImage: `url(${LessonSessionBg})` }}>
              <p className="w-50">Lesson and Session Plans</p>
            </div>
            <div className={`${StylesA.card_body}`} style={{ maxHeight: "200px", overflowY: "auto" }}>
              {lesson_session_plan.map((activity, index) => {
                return displayActivity(activity, index)
              })}
            </div>
          </Col>
        )
      } else {
        return null
      }
    } else if (type === "breakout_card") {
      if (breakout_activity_fetched && breakout_plan && breakout_plan.length > 0) {
        return (
          <Col xs="12" sm="6" md="6" lg="3" className={`${StylesA.recent_card} px-0 mx-1`}>
            <div className={`${StylesA.card_header}`} style={{ backgroundImage: `url(${BreakoutBg})` }}>
              <p className="w-50">Breakout Groups and Sessions</p>
            </div>
            <div className={`${StylesA.card_body}`} style={{ maxHeight: "200px", overflowY: "auto" }}>
              {breakout_plan.map((activity, index) => {
                return displayActivity(activity, index)
              })}
            </div>
          </Col>
        )
      } else {
        return null
      }
    } else if (type === "resource_card") {
      if (resource_activity_fetched && resource_activity && resource_activity.length > 0) {
        return (
          <Col xs="12" sm="6" md="6" lg="3" className={`${StylesA.recent_card} px-0 mx-1`}>
            <div className={`${StylesA.card_header}`} style={{ backgroundImage: `url(${ResourceBg})` }}>
              <p className="w-50">Resources</p>
            </div>
            <div className={`${StylesA.card_body}`} style={{ maxHeight: "200px", overflowY: "auto" }}>
              {resource_activity.map((activity, index) => {
                return displayActivity(activity, index)
              })}
            </div>
          </Col>
        )
      } else {
        return null
      }
    } else if (type === "external_links") {
      if (extlinks_activity_fetched && extlinks_activity && extlinks_activity.length > 0) {
        return (
          <Col xs="12" sm="6" md="6" lg="3" className={`${StylesA.recent_card} px-0 mx-1`}>
            <div className={`${StylesA.card_header}`} style={{ backgroundImage: `url(${LinksToDownload})` }}>
              <p className="w-50">Links to Download</p>
            </div>
            <div className={`${StylesA.card_body}`} style={{ maxHeight: "160px", overflowY: "auto" }}>
              {extlinks_activity.map((activity, index) => {
                activity["type"] = "extlinks_activity"
                return displayActivity(activity, index)
              })}
            </div>
            <p
              className="text-white mt-3 pl-2"
              style={{ textDecoration: "underline", cursor: "pointer", fontSize: "12px", fontWeight: "normal" }}
              onClick={() => {
                setShowExtLinkModal(true)
              }}
            >
              See All
            </p>
          </Col>
        )
      } else {
        return null
      }
    }
  }
  const displayActivity = (activity, index) => {
    if (activity.type === "lesson_plan") {
      return (
        <div key={`${activity.name.toLowerCase().split(" ").join("-")}-${index}`} className="d-flex align-items-center my-1">
          <p style={{ width: "75%" }}>
            A new topic,{" "}
            <span className="text-white" style={{ textDecoration: "underline" }}>
              {activity.name}
            </span>{" "}
            was added by you
          </p>
          <p style={{ width: "25%", textAlign: "right" }}>{`${pollTimeDifference(activity.created)} ago`}</p>
        </div>
      )
    } else if (activity.type === "session_plan") {
      return (
        <div key={`${activity.name.toLowerCase().split(" ").join("-")}-${index}`} className="d-flex align-items-center my-1">
          <p style={{ width: "75%" }}>
            A new session,{" "}
            <span className="text-white" style={{ textDecoration: "underline" }}>
              {activity.name}
            </span>{" "}
            was added by you
          </p>
          <p style={{ width: "25%", textAlign: "right" }}>{`${pollTimeDifference(activity.created)} ago`}</p>
        </div>
      )
    } else if (activity.type === "breakout_session") {
      return (
        <div key={`${activity.name.toLowerCase().split(" ").join("-")}-${index}`} className="d-flex align-items-center my-1">
          <p style={{ width: "75%" }}>
            <span className="text-white" style={{ textDecoration: "underline" }}>
              {activity.name}
            </span>{" "}
            was added by you
          </p>
          <p style={{ width: "25%", textAlign: "right" }}>{`${pollTimeDifference(activity.created)} ago`}</p>
        </div>
      )
    } else if (activity.type === "resource_activity") {
      return (
        <div key={`${activity.resource_name.toLowerCase().split(" ").join("-")}-${index}`} className="d-flex align-items-center my-1">
          <p style={{ width: "75%" }}>
            <span className="text-white" style={{ textDecoration: "underline" }}>
              {activity.resource_name}
            </span>{" "}
            {`${activity.resource_type} was added by you`}
          </p>
          <p style={{ width: "25%", textAlign: "right" }}>{`${pollTimeDifference(activity.created)} ago`}</p>
        </div>
      )
    } else if (activity.type === "extlinks_activity") {
      return (
        <>
          {activity.entity == 1 ? (
            <div key={`${activity.type}-${activity.entity}-${index}`} style={{ fontSize: "12px" }} className={`d-flex`}>
              <div>{activity.totalLinks} Links were added</div>
              <div className={`ml-auto`}>{activity.timeAgo} ago</div>
            </div>
          ) : (
            <div
              key={`${activity.type}-${activity.entity}-${index}`}
              className="d-flex align-items-center my-1"
              style={{ borderRadius: "9px", border: "1px solid transparent", backgroundColor: "#42506C" }}
            >
              <p style={{ width: "10%" }}>
                {activity.data.icon !== null ? (
                  <img src={activity.data.icon} className="m-0 mt-1 ml-1" style={{ width: "20px", objectFit: "contain" }} />
                ) : (
                  <FontAwesomeIcon className="m-0 mt-2 ml-1" icon={faLink} color="#fff" size="1x" style={{ width: "20px", objectFit: "contain" }} />
                )}
              </p>
              <p className={`pl-2`} style={{ width: "90%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontWeight: "bold" }}>
                {activity.data.link}
              </p>
            </div>
          )}
        </>
      )
    }
  }
  const pollTimeDifference = (timestamp) => {
    let resulted_min = 0,
      resulted_hr = 0,
      resulted_day = 0,
      resulted_time = ""
    let current_time = utcDateTime.utcDateTime("today", "time_in_sec")
    let poll_created_time = utcDateTime.utcDateTime(timestamp, "time_in_sec")
    let time_difference = Math.floor((current_time - poll_created_time) / 1000)
    resulted_day = Math.floor(time_difference / (60 * 60 * 24))
    resulted_hr = Math.floor((time_difference - resulted_day * 24 * 60 * 60) / (60 * 60))
    resulted_min = Math.floor((time_difference - (resulted_day * 24 * 60 * 60 + resulted_hr * 60 * 60)) / 60)
    if (resulted_day > 0) {
      resulted_day = resulted_day > 1 ? `${resulted_day} days ` : `${resulted_day} day `
    } else {
      resulted_day = ""
    }
    if (resulted_hr > 0) {
      resulted_hr = resulted_hr > 1 ? `${resulted_hr} hrs ` : `${resulted_hr} hr `
    } else {
      resulted_hr = ""
    }
    if (resulted_min > 0) {
      resulted_min = resulted_min > 1 ? `${resulted_min} mins` : `${resulted_min} min`
    } else {
      resulted_min = ""
    }
    // if (resulted_day.length > 0) {
    //   resulted_time = resulted_day
    // } else {
    //   resulted_time = `${resulted_hr}${resulted_min}`
    // }
    if (resulted_day.length > 0) {
      resulted_time = resulted_day
    } else if (resulted_hr.length > 0) {
      resulted_time = resulted_hr
    } else {
      resulted_time = resulted_min
    }
    if (resulted_time.length === 0) {
      resulted_time = " Few seconds"
    }
    return resulted_time
  }
  const parentFetchRecentActivity = (value, type) => {
    setTimeout(() => {
      if (type === "lesson_session") {
        if (value === "true") {
          setLessonSessionActivityFetched(false)
        } else {
          setLessonSessionActivityFetched(true)
        }
      } else if (type === "breakout") {
        if (value === "true") {
          setBreakoutActivityFetched(false)
        } else {
          setBreakoutActivityFetched(true)
        }
      } else if (type === "resource") {
        if (value === "true") {
          setResourceActivityFetched(false)
        } else {
          setResourceActivityFetched(true)
        }
      } else if (type === "external_link") {
        if (value === "true") {
          setExtLinksActivityFetched(false)
        } else {
          setExtLinksActivityFetched(true)
        }
      }
    }, 300)
  }

  function showMainSection() {
    if (activeSection === "Home" || activeSection === "BreakoutGroup" || activeSection === "Resource") {
      return (
        <React.Fragment>
          <div className={`${StylesA.go_live_section} mx-3 pr-4`} style={{ maxWidth: "max-content" }}>
            <div>
              <img src={GoliveBanner} alt={`list`} className={`my-auto`} style={{ objectFit: `contain`, width: "250px" }} />
            </div>
            <div className="d-flex flex-column">
              {showGoliveSection()}
              {viewInsights()}
              {(insightStore?.type === "class_burndown" || insightStore?.type === "session_burndown") && (
                <InsightModal show={true} template_id={templateId} training_id={trainingId} module_id={insightStore.activeModuleData?.id} />
              )}
            </div>
          </div>
          {lesson_session_plan.length + breakout_plan.length + resource_activity.length + extlinks_activity.length === 0 ? null : (
            <Col xs="12" sm="12" md="12" lg="12" className={`${StylesA.recent_activities} pt-5`}>
              <p>Recent Activities</p>
              <Col xs="12" sm="12" md="12" lg="12" className={`${StylesA.recent_cards_section} px-0`}>
                {displayRecentActivity("breakout_card")}
                {displayRecentActivity("lesson_session_card")}
                {displayRecentActivity("resource_card")}
                {displayRecentActivity("external_links")}
              </Col>
            </Col>
          )}
          <Col xs="12" sm="12" md="12" lg="12" className={`${StylesA.class_setup_section} py-5`}>
            <Col xs="12" sm="12" md="12" lg="6" xl="5" className={`${StylesA.class_setup_nav} px-0 mb-3`}>
              <div>
                <p style={{ color: "white", fontWeight: "bold", fontSize: "16px", padding: "10px 20px" }}>Setup your classroom</p>
              </div>
              <div className={`${StylesA.class_setup_nav_div}`} style={{ backgroundColor: "#303C54" }}>
                <p>Things to do</p>
              </div>
              <div className={`${StylesA.class_setup_nav_div}`}>
                <p>Tutorials</p>
              </div>
              <div className={`${StylesA.class_setup_nav_div}`}>
                <p>Tips and Tricks</p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" className={`${StylesA.things_to_do} px-0`}>
              <Col xs="12" sm="5" md="5" lg="3" xl="2" className={`${StylesA.to_do_card} px-0`}>
                <div className={`${StylesA.to_do_card_header}`} style={{}}>
                  <img src={ListIcon} alt={`list`} className={`my-auto`} style={{ objectFit: `contain` }} />
                </div>
                <div className={`${StylesA.to_do_card_body}`}>
                  <Button variant="none">Add Guidelines</Button>
                  <Button variant="none">Add Rewards</Button>
                </div>
              </Col>
              <Col xs="12" sm="5" md="5" lg="3" xl="2" className={`${StylesA.to_do_card} px-0`}>
                <div className={`${StylesA.to_do_card_header}`} style={{}}>
                  <img src={LessonSessionIcon} alt={`list`} className={`my-auto`} style={{ objectFit: `contain` }} />
                </div>
                <div className={`${StylesA.to_do_card_body}`}>
                  <Button
                    variant="none"
                    className={`${StylesA.border_on_hover} px-0`}
                    onClick={() => navigate(`/app/trainings/training-content/${trainingId}`)}
                  >
                    View Class Content
                  </Button>
                </div>
              </Col>
              <Col xs="12" sm="5" md="5" lg="3" xl="2" className={`${StylesA.to_do_card} px-0`}>
                <div className={`${StylesA.to_do_card_header}`} style={{}}>
                  <img src={BreakoutGroup} alt={`list`} className={`my-auto`} style={{ objectFit: `contain` }} />
                </div>
                <div className={`${StylesA.to_do_card_body}`}>
                  <Button
                    variant="none"
                    onClick={() => {
                      dispatchSidebarStore({ type: SET_CURRENT_NAV_TAB, payload: "BreakoutGroup" })
                      dispatchClassroomStore({ type: SET_OPENED_OVERLAY_SECTION, payload: "BreakoutGroup" })
                    }}
                  >
                    {breakoutRoomCount == 0 ? "Create" : "Edit"} Breakout Rooms
                  </Button>
                  <Button
                    variant="none"
                    onClick={() => {
                      if (breakoutSessionCount === 0) {
                        setShowSessionModal(true)
                      } else {
                        setActiveSection("BreakoutSession")
                      }
                    }}
                  >
                    {breakoutSessionCount == 0 ? "Create" : "Edit"} Breakout Sessions
                  </Button>
                </div>
              </Col>
              <Col xs="12" sm="5" md="5" lg="3" xl="2" className={`${StylesA.to_do_card} px-0`}>
                <div className={`${StylesA.to_do_card_header}`} style={{}}>
                  <img src={PollIcon} alt={`list`} className={`my-auto`} style={{ objectFit: `contain` }} />
                </div>
                <div className={`${StylesA.to_do_card_body}`}>
                  <Button
                    variant="none"
                    onClick={() => {
                      dispatchSidebarStore({ type: SET_NAV_AND_RESOURCE_TYPE, payload: { navTab: "Poll", resourceType: "Polls" } })
                    }}
                  >
                    Add Poll and Pop Quiz
                  </Button>
                </div>
              </Col>
              <Col xs="12" sm="5" md="5" lg="3" xl="2" className={`${StylesA.to_do_card} px-0`}>
                <div className={`${StylesA.to_do_card_header}`} style={{}}>
                  <img src={ResourcesIcon} alt={`list`} className={`my-auto`} style={{ objectFit: `contain` }} />
                </div>
                <div className={`${StylesA.to_do_card_body}`}>
                  <Button
                    variant="none"
                    onClick={() => {
                      dispatchSidebarStore({ type: SET_NAV_AND_RESOURCE_TYPE, payload: { navTab: "Resource", resourceType: "Videos" } })
                    }}
                  >
                    Add Resources
                  </Button>
                </div>
              </Col>
              <Col xs="12" sm="5" md="5" lg="3" xl="2" className={`${StylesA.to_do_card} px-0`}>
                <div className={`${StylesA.to_do_card_header}`} style={{}}>
                  <img src={ExternalLinksDownload} alt={`list`} className={`my-auto`} style={{ objectFit: `contain` }} />
                </div>
                <div className={`${StylesA.to_do_card_body}`}>
                  <Button
                    variant="none"
                    onClick={() => {
                      setShowExtLinkModal(true)
                    }}
                  >
                    Add links to download
                  </Button>
                </div>
                {showExtLinkModalCode()}
              </Col>

              <Col xs="12" sm="5" md="5" lg="3" xl="2" className={`${StylesA.to_do_card} px-0`}>
                <div className={`${StylesA.to_do_card_header}`} style={{}}>
                  <img src={SlideshowIcon} alt={`list`} className={`my-auto`} style={{ objectFit: `contain` }} />
                </div>
                <div className={`${StylesA.to_do_card_body}`}>
                  <Button variant="none">Add Music and Slideshow</Button>
                </div>
              </Col>
            </Col>
          </Col>
        </React.Fragment>
      )
    } else if (activeSection === "ExternalLinksToDownload") {
    } else if (activeSection === "BreakoutSession") {
      return (
        <Col xs="12" sm="12" md="12" lg="12" className="d-flex" style={{ justifyContent: leftNavOpen ? "flex-end" : "center" }}>
          <Col xs="12" sm="12" md="8" lg="9">
            <BreakoutSession
              allResources={allResources}
              setHomepageAllResource={(value) => setAllResources(value)}
              currentSelectedResourcesType={currentSelectedResourcesType}
              resetDragState={() => {
                setDraggedItem("")
                setDragEnded(false)
              }}
              dragEnded={dragEnded}
              draggedItem={draggedItem}
              activeSection={activeSection}
              setActiveSection={(item) => setActiveSection(item)}
              fetchRecentActivity={parentFetchRecentActivity}
            />
          </Col>
        </Col>
      )
    } else if (activeSection === "LessonPlan") {
      return (
        <Col xs="12" sm="12" md="12" lg="12" className="d-flex" style={{ justifyContent: leftNavOpen ? "flex-end" : "center" }}>
          <Col xs="12" sm="12" md="8" lg="9">
            {/* Lesson Plan Component */}
          </Col>
        </Col>
      )
    } else if (activeSection === "SessionPlan") {
      return (
        <Col xs="12" sm="12" md="12" lg="12" className="d-flex">
          <Col xs="12" sm="12" md="12" lg="12">
            {/* Session plan Component */}
          </Col>
        </Col>
      )
    } else if (activeSection === "Participants") {
      return (
        <Col xs="12" sm="12" md="12" lg="12" className="d-flex" style={{ justifyContent: leftNavOpen ? "flex-end" : "center" }}>
          <Col xs="12" sm="12" md="8" lg="9">
            <ParticipantDetail
              leftNavOpen={leftNavOpen}
              allResourcesChanged={allResourcesChanged}
              allResources={allResources}
              setHomepageAllResource={(value) => setAllResources(value)}
              openNavWithResourceSection={openNavWithResourceSection}
              currentSelectedResourcesType={currentSelectedResourcesType}
              resetDragState={() => {
                setDraggedItem("")
                setDragEnded(false)
              }}
              dragEnded={dragEnded}
              draggedItem={draggedItem}
              template_id={templateId}
              training_id={trainingId}
              activeSection={activeSection}
              setActiveSection={(item) => setActiveSection(item)}
              fetchRecentActivity={parentFetchRecentActivity}
              participant={participant}
            />
          </Col>
        </Col>
      )
    }
  }
  const updateLessonSessionUrl = (url_type) => {
    setActiveSection(url_type)
    if (url_type === "LessonPlan") {
      window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}#type=lesson-plan`)
    } else if (url_type === "SessionPlan") {
      window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}#type=session-plan`)
    } else if (url_type === "BreakoutSession") {
      window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}#type=breakout-session`)
    } else if (url_type === "ExternalLinksToDownload") {
      window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}#type=external-links-to-download`)
    }
  }

  function showExtLinkModalCode() {
    return (
      <Modal
        show={showExtLinkModal}
        onHide={() => {
          setShowExtLinkModal(false)
        }}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
        className="classroom-poll-modal"
        style={{ paddingTop: "2rem", border: "none", color: "#E0E2E5" }}
      >
        <Modal.Body
          style={{
            borderRadius: "8px",
            backgroundColor: "#212C42",
            padding: "0",
            border: "1px solid #929BBD",
            boxShadow: "0px 3px 12px #00000099",
            overflowY: "auto",
          }}
        >
          <Col lg="12" md="12" sm="12" xs="12" className={`px-0 d-inline-flex`}>
            <Col lg="12" md="12" sm="12" xs="12" className={`${StylesA.breakout_main_section} px-0 w-100`}>
              <div className={`${StylesA.breakout_header} d-flex pl-4 pt-4`}>
                <p>
                  <b>Links to Download</b>
                </p>
                <img
                  src={CancelWhite}
                  onClick={() => {
                    setShowExtLinkModal(false)
                    fetchExtLinksRecentActivity()
                  }}
                  alt={`Cancel`}
                  className={`ml-auto mt-1 mr-2 pointer`}
                  style={{ objectFit: `contain`, height: `15px` }}
                />
              </div>
              <div>
                <ExternalLinksToDownload templateId={templateId} activeSection={activeSection} setActiveSection={(item) => setActiveSection(item)} />
              </div>
            </Col>
          </Col>
        </Modal.Body>
      </Modal>
    )
  }
  function breakoutSessionModal() {
    return (
      <Modal
        show={showSessionModal}
        onHide={() => {
          setShowSessionModal(false)
        }}
        size="md"
        aria-labelledby="example-custom-modal-styling-title"
        className="classroom-poll-modal"
        style={{ paddingTop: "2rem", border: "none", color: "#E0E2E5" }}
      >
        <Modal.Body
          style={{
            borderRadius: "8px",
            backgroundColor: "#212C42",
            padding: "0",
            border: "1px solid #929BBD",
            boxShadow: "0px 3px 12px #00000099",
            overflowY: "auto",
          }}
        >
          <Col xs="12" sm="12" md="12" lg="12" className="p-3">
            <div className={`${StylesA.message_modal_header}`} style={{ backgroundImage: `url(${BreakoutSessionModalImage})` }}>
              <p className="w-50">Breakout Session</p>
            </div>
            <div className={`${StylesA.message_modal_body}`} style={{ maxHeight: "190px", overflowY: "auto" }}>
              <p>
                Create breakout rooms & sessions within your live classes by specifying durations, intervals, and names as per your choice. Through the breakout
                sessions, you can help your learners to interact & participate in group-based discussions & activities.
              </p>
              <Button
                onClick={() => {
                  setBreakoutSessionCount(1)
                  // updateLessonSessionUrl("BreakoutSession")
                  dispatchSidebarStore({ type: SET_CURRENT_NAV_TAB, payload: "BreakoutSession" })
                  setShowSessionModal(false)
                }}
                className={`${StylesA.add_button_large} ${StylesA.border_on_hover}`}
                variant="none"
              >
                Create Breakout Session
              </Button>
            </div>
          </Col>
        </Modal.Body>
      </Modal>
    )
  }

  if (trainingDetailFetched) {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Col xs="12" sm="12" md="12" lg="12" className="pr-0" style={{ backgroundColor: "#19253B", height: "100vh" }}>
          <ClassroomSidebar parentComponent="classroom-dashboard" setParticipantData={setParticipantData} participantData={participantData} />

          <div style={{ height: "100vh", overflowY: "auto" }}>
            <Col xs="12" sm="12" md="12" lg="12" className={`${StylesA.classroom_header} p-3 d-flex`} style={{ justifyContent: "space-between" }}>
              {classroomStore.openedOverlaySection === "BreakoutGroup" && <BreakoutGroups />}
              <div className={`${StylesA.header_left_section}`}>
                <h5>{trainingDetail?.course}</h5>
                {/*<p><b>2 days left</b></p>*/}
                <p>{formatDate.displayTrainingDate(trainingDetail)}</p>
                <p>{formatDate.displayTimeDetail(trainingDetail)}</p>
              </div>
              <div className={`${StylesA.header_right_section}`}>
                <NotificatioBellAndModal circle={false} notficationType="0" trainingId={null} />
                <Button
                  onClick={() => setShowSharedArtifact(true)}
                  variant="none"
                  className={`border_on_hover mr-3 text-center align-items-center d-inline-flex text-white`}
                  style={{ backgroundColor: `#303C54`, borderRadius: "15px", padding: "4px 7px" }}
                >
                  <img src={share} alt={`Share Course`} className={`my-auto mr-1`} style={{ objectFit: `contain`, height: `14px` }} />
                  <p style={{ fontSize: "14px", margin: "0px" }}>Share Class room</p>
                </Button>
                <Link to={`/app/dashboard`} style={{ color: `white`, borderRadius: "15px" }} className={`border_on_hover`}>
                  <div className="d-flex align-items-center" style={{ borderRadius: "15px", backgroundColor: "#303C54", padding: "4px 7px" }}>
                    <img src={CancelWhite} alt={`Cancel`} className={`my-auto mr-1`} style={{ objectFit: `contain`, height: `13px` }} />
                    <p style={{ margin: "0px", fontSize: "14px" }}>Exit Class Dashboard</p>
                  </div>
                </Link>
              </div>
            </Col>
            {showMainSection()}
            {state.muralAssessment && state.showMuralCanvas && <MuralCanvas showCanvasControl={true} onHideCallback={openMuralModalForAssessment} />}
            <SharedArtifact show={showSharedArtifact} hide={() => setShowSharedArtifact(false)} trainingId={trainingId} />
          </div>
        </Col>
        {/*{lessonSessionModal()}*/}
        {breakoutSessionModal()}
        {questionStore.show_question_modal?.type === "question" ? <QuestionSingle /> : null}
      </DragDropContext>
    )
  } else {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
        <Spinner animation="border" variant="secondary" />
      </Col>
    )
  }
}

export default ClassroomDashboard
